
import { defineComponent, reactive, toRefs, ref, onMounted, watch } from "vue";
import { getLogEtapa } from "@/services/OficinaDigital";
import { ContentLoader } from 'vue-content-loader';
import store from "@/store/index";
import { hideModal } from '@/core/helpers/dom';

export default {
  name: "LogdeVisualizacaoModal",
  props: {
    id: {
      type: String,
      required: true,
    },
    logEtapas: {
      type: Object,
      required: true,
    },
  },
  components:{
	  ContentLoader
  },
  setup(props) {
  let logs = ref();
  let maiorVersao = ref(0);
  let codConcessionaria = ref();
	let animate = ref(true);
  const distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");
  let dominio_orcamento = store.getters.layoutConfig("distribuidor.dominio_orcamento");

    watch(() => props.logEtapas, () => {
        if(props.logEtapas?.codConcessionaria && props.logEtapas?.checklist && props.logEtapas?.codChecklist){
          listaLogEtapa(
            props.logEtapas.codConcessionaria,
            props.logEtapas.checklist,
            props.logEtapas.codChecklist
          );
        }
      }
    );

    async function listaLogEtapa(codConcessionaria, checklist, codChecklist) {
      animate.value = true

      logs.value = [];
      let response = await getLogEtapa(codConcessionaria,checklist,codChecklist);

      animate.value = false

      response = response.map(logEtapa => {
        const arrayNomeEtapa = logEtapa.etapa.split("-");
        const versao = arrayNomeEtapa[0] == 'Nova precificação feita ' ? Number(arrayNomeEtapa.splice(-1)) : null;
        return {
          ...logEtapa,
          versao
        }
      });
      
      const refMaiorVersao = response.map(logEtapa => logEtapa.versao).reduce((versaoA, versaoB) => {
        return Math.max(versaoA ?? 0, versaoB ?? 0);
      });

      maiorVersao.value = refMaiorVersao ?? 0;

      logs.value = [...response];
    };

    function closeModal(){
			const refModal = document.getElementById(props.id);
			hideModal(refModal);
		}

    return {
      logs,
      animate,
      dominio_orcamento,
      codConcessionaria, 
      maiorVersao,
      closeModal
    };
  },
};
